import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "./firebase";
import { toast } from "react-toastify";
import "./Login.css";
import headimg from './ing.png';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in Successfully");
      window.location.href = "/dashboard";
      toast.success("User logged in Successfully", {
        position: "top-center",
      });
    } catch (error) {
      console.log(error.message);

      let errorMessage = "Contact to Admin"; // Default error message

      // Customize the error message based on the error code
      if (error.code === 'auth/invalid-email') {
        errorMessage = "Invalid email format. Please check your email.";
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = "No user found with this email.";
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = "Incorrect password. Please try again.";
      }

      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="flex bg">
      <form onSubmit={handleSubmit}>
        <div className="f-box">
          <img
            src={headimg}
            alt="Logo"
            style={{
              height: '200px',
              marginTop: '0px',
            }}
          />

          <div className="input">
            <label className="leb">Email address</label>
            <input
              type="email"
              className=""
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input">
            <label className="leb">Password</label>
            <input
              type="password"
              className=""
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="">
            <button type="submit" className="loginbtn">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
